.SignInUpButton{
    font-size: 25px;
    color: white;
    text-shadow: 2px 2px black;
}

#inputForgotPassword{
    width: 300px;
}

#labelId{
    font-size: 25px;
    color: whitesmoke;
    text-shadow: 2px 2px black;
}

.card4{
    background-Size: 1300px 1100px;
    background-Position: center ;
    background-repeat:repeat;
    height: 500px;
  }