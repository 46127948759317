
#forgotId{
  font-size: 20px;
  font-weight: bolder;
  text-shadow: 2px 2px black;
  color: white;
}

.btn{
    align-content: space-between;
    margin: 1%;
}

.title{
    display: flex;
    justify-content: center;
    font-size: xx-large
}


  .container-input {
    width: 500px;
    padding-right: 60px;
    padding-left: 60px;
    margin-right: auto;
    margin-left: auto;
  }

  .card1{
    background-Size: 500px 300px;
    background-Position: center ;
    background-repeat: repeat;
  }
 
