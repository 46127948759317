.productsMain{
    display: flex;
    justify-self:center;
    flex-direction: column;
}


.card3{
    background-Size: 1000px 900px;
    background-Position: center ;
    background-repeat: repeat;
  }