h1 {
    margin: 0;
    padding: 0;
  }

  .popup {
    position: fixed;
    width: 100%;
    height: 100%;
    min-width: 250px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
  }
  .popup_inner {
      position: absolute;
      left: 25%;
      right: 25%;
      top: 25%;
      min-width: 250px;
    bottom: 25%;
    margin: auto;
    background: white;
  }

  .mainTitle{
      background-color: lightgray;
  }